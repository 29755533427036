import { http } from '@/http/axios.js'
export function getCategoryListAPI() {
  return http({
    url: '/admin/doc/agreement/list',
    method: 'get'
  })
}
// 详情
export function getDetailAPI(docKey) {
  return http({
    url: '/admin/doc/agreement/detail',
    method: 'get',
    params: { docKey }
  })
}
// 编辑
export function handleEditAPI(data) {
  return http({
    url: '/admin/doc/agreement/edit',
    method: 'post',
    data
  })
}
